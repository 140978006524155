<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.period.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 4 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      :label="$getVisibleNames('mesh.periodtype', false, 'Régimen')"
      label-for="input-regime"
      class="label mt-1 mb-2 mx-0"
    >
      <b-form-select
        id="input-regime"
        :options="regimes"
        v-model="$v.period.period_type.$model"
        value-field="id"
        text-field="name"
        :state="validateState('period_type')"
        @change="
          () => {
            period.start_date = null;
            period.end_date = null;
            period.weeks = 0;
          }
        "
        aria-describedby="input-regime-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-regime-feedback"
        >Debe seleccionar un
        {{
          $getVisibleNames("mesh.periodtype", false, "Régimen")
        }}.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Inicio"
      label-for="input-execution-date"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-datepicker
        id="input-execution-date"
        v-model="$v.period.start_date.$model"
        :state="validateState('start_date')"
        @input="numberOfWeeks"
        aria-describedby="input-execution-date-feedback"
        :date-disabled-fn="dateDisabled"
        size="sm"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-execution-date-feedback">
        <div
          v-if="
            period.end_date == null ||
            new Date(period.end_date) < new Date(period.start_date)
          "
        >
          Este campo es obligatorio y no puede ser igual o mayor que la fecha de
          término.
        </div>
        <div v-if="!validateNoOverlapping(period.start_date, period.end_date)">
          No puede contener otro periodo dentro del rango asignado.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="Término"
      label-for="input-end-date"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-datepicker
        id="input-end-date"
        v-model="$v.period.end_date.$model"
        :state="validateState('end_date')"
        @input="numberOfWeeks"
        aria-describedby="input-end-date-feedback"
        :date-disabled-fn="dateDisabled"
        size="sm"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-end-date-feedback">
        <div
          v-if="
            period.end_date == null ||
            new Date(period.end_date) < new Date(period.start_date)
          "
        >
          Este campo es obligatorio y no puede ser igual o menor que la fecha de
          inicio.
        </div>
        <div v-if="!validateNoOverlapping(period.start_date, period.end_date)">
          No puede contener otro periodo dentro del rango asignado.
        </div></b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Cierre de Secciones"
      label-for="input-close_sections_date"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-datepicker
        id="input-close_sections_date"
        v-model="$v.period.close_sections_date.$model"
        :state="validateState('close_sections_date')"
        aria-describedby="input-close_sections_date-feedback"
        :date-disabled-fn="closeSectionsDateDisabled"
        size="sm"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-close_sections_date-feedback">
        <div
          v-if="$moment(period.close_sections_date) < $moment(period.end_date)"
        >
          Este campo no puede ser menor que la fecha de termino.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      label="Semanas"
      label-for="input-weeks"
      class="label m-0"
    >
      <b-form-input
        id="input-weeks"
        v-model="$v.period.weeks.$model"
        type="number"
        min="1"
        :state="validateState('weeks')"
        aria-describedby="input-weeks-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-weeks-feedback"
        >Este campo debe ser un entero mayor a cero.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="!isNaN(this.period.id)"
      label-for="input-modification-date"
      label-cols="0"
      label-cols-sm="0"
      class="p-0 m-0 mt-2"
    >
      <ModificationDate :Information="period"></ModificationDate>
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(this.period.id)"
        class="col"
        style="text-align: left"
      >
        <b-button class="mr-1" size="sm" variant="danger" @click="deletePeriod"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import { mapGetters } from "vuex";
import * as names from "@/store/names";
import { validationMixin } from "vuelidate";
import { required, minLength, minValue } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
export default {
  name: "PeriodForm",
  mixins: [validationMixin],
  components: {
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    Period: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          start_date: null,
          end_date: null,
          close_sections_date: null,
          period_type: null,
          weeks: 0,
          updated_by: null,
          update_date: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      period: { ...this.Period },
    };
  },
  validations: {
    period: {
      name: {
        required,
        minLength: minLength(4),
      },
      start_date: {
        required,
        maxValue(val, { end_date }) {
          return new Date(end_date) > new Date(val);
        },
        validateDate(val, { end_date }) {
          return this.validateNoOverlapping(val, end_date);
        },
      },
      end_date: {
        required,
        minValue(val, { start_date }) {
          return new Date(val) > new Date(start_date);
        },
        validateDate(val, { start_date }) {
          return this.validateNoOverlapping(start_date, val);
        },
      },
      close_sections_date: {
        minValue(val, { end_date }) {
          return this.$moment(val) >= this.$moment(end_date) || val == null;
        },
      },
      weeks: {
        required,
        minValue: minValue(1),
      },
      period_type: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      regimes: names.REGIMES,
      periods: names.PERIODS,
    }),
    restrictedPeriods() {
      if (this.period.period_type == null) return [];
      return this.periods
        .filter(
          (x) =>
            x.period_type == this.period.period_type &&
            x.start_date != null &&
            x.end_date != null &&
            x.id != this.period.id
        )
        .map((x) => ({
          start_date: new Date(x.start_date),
          end_date: new Date(x.end_date),
        }));
    },
  },
  created() {},
  methods: {
    validateNoOverlapping(start_date, end_date) {
      // Convertir las fechas de inicio y fin del rangoInput a objetos Date
      const start_date_input = new Date(start_date);
      const end_date_input = new Date(end_date);
      // Usa el método .some para verificar si hay alguna superposición
      const hasOverlap = this.restrictedPeriods.some((rangoActual) => {
        return (
          start_date_input <= new Date(rangoActual.end_date) &&
          end_date_input >= new Date(rangoActual.start_date)
        );
      });
      return !hasOverlap; // Retorna true si no hay superposición, false si la hay
    },
    numberOfWeeks() {
      if (this.period.end_date != null)
        this.period.close_sections_date = this.period.end_date;
      if (this.period.start_date != null && this.period.end_date != null) {
        // Convierte las fechas de formato string a objetos Date
        const startDateObject = new Date(this.period.start_date);
        const endDateObject = new Date(this.period.end_date);
        // Calcula la cantidad de milisegundos entre las fechas
        const timeDifference = endDateObject - startDateObject;
        // Calcula la cantidad de semanas redondeando hacia abajo
        const numberOfWeeks = Math.floor(
          timeDifference / (1000 * 60 * 60 * 24 * 7)
        );
        if (numberOfWeeks > 0) this.period.weeks = numberOfWeeks;
      }
    },
    dateDisabled(ymd, date) {
      // Convierte la fecha a un objeto Date
      const currentDate = new Date(date);
      // Define los rangos de fechas que deseas deshabilitar
      const disabledRanges = this.restrictedPeriods;
      // Verifica si la fecha está dentro de alguno de los rangos y debe ser deshabilitada
      for (const range of disabledRanges) {
        if (currentDate >= range.start_date && currentDate <= range.end_date) {
          return true;
        }
      }
      // Si la fecha no coincide con ningún rango, no la deshabilitamos
      return false;
    },
    closeSectionsDateDisabled(ymd, date) {
      const currentDate = this.$moment(date);
      if (
        this.period.end_date != null &&
        currentDate >= this.$moment(this.period.end_date)
      ) {
        return false;
      } else return true;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.period[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.period.$touch();
      if (this.$v.period.$anyError) {
        return;
      }
      if (isNaN(this.period.id)) {
        this.createPeriod();
      } else this.updatePeriod();
    },
    createPeriod() {
      this.$store.dispatch(names.POST_PERIOD, this.period).then((response) => {
        toast(
          String(
            this.$getVisibleNames("mesh.period", false, "Período") + " creada."
          )
        );
        this.$emit("created", response);
      });
    },
    updatePeriod() {
      this.$store
        .dispatch(names.UPDATE_PERIOD, this.period)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames("mesh.period", false, "Período") +
                " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deletePeriod() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.period",
          false,
          "Período"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_PERIOD, this.period.id);
          this.$emit("deleted", this.period);
        }
      });
    },
  },
};
</script>

<style scoped>
div >>> div .bg-light {
  background: #ffcccba4 !important;
}
div >>> .small {
  display: none !important;
}
</style>